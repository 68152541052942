<script setup>
    import {
        Navigation,
        Pagination,
        Scrollbar,
        A11y,
        FreeMode,
        Keyboard,
        Mousewheel,
    } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import 'swiper/css'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'
    import 'swiper/css/scrollbar'
    import { Link } from '@inertiajs/vue3'
    import { onMounted, onBeforeUnmount } from 'vue'

    const props = defineProps(['items', 'title', 'rounded'])

    // Destructure the props
    const { items, title, rounded = true } = props

    const onTouchMove = event => {
        event.preventDefault()
    }

    const modules = [Navigation, Scrollbar, A11y, FreeMode, Keyboard, Mousewheel, Pagination]

    const swiperOptions = {
        // Default parameters
        mousewheel: {
            forceToAxis: true,
            sensitivity: 1,
            releaseOnEdges: true,
        },
        navigation: {
            enabled: true,
            prevEl: '.carousel-button-prev',
            nextEl: '.carousel-button-next',
        },

        cssMode: true,
        touchStartPreventDefault: true,
        touchMoveStopPropagation: false,
        touchStartForcePreventDefault: true,
        preventClicksPropagation: true,
        preventClicks: true,
        touchAngle: 45,
        touchEventsTarget: 'wrapper',
        longSwipesMs: 300,
        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 3.5,
                spaceBetween: 10,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3.5,
                spaceBetween: 20,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 4.5,
                slidesPerGroup: 4,
                spaceBetween: 20,
            },

            900: {
                slidesPerView: 5.5,
                slidesPerGroup: 5,
                spaceBetween: 20,
            },
            1024: {
                slidesPerView: 5.5,
                slidesPerGroup: 5,
                spaceBetween: 20,
            },
        },
    }
    if (items.length > 13 && !items.find(item => item.id === 50)) {
        items.push({
            id: 50,
            profile_url: 'creator.index',
            href: '/profile/view-more',
            avatar: import.meta.env.VITE_CDN_URL + '/images/view-more-avatar.png',
            title: 'View More',
            lastTile: true,
        })
    }
    const handleImageError = item => {
        // This function will be called when the image fails to load
        // You can set a backup image source here
        item.avatar_images.thumb_webp =
            import.meta.env.VITE_CDN_URL + '/images/placeholder_avatar.png'
    }
</script>

<template :touch="{ left: null, right: null }">
    <div
        id="recommended"
        class="relative mx-auto max-w-7xl pb-5 pl-[10px] pt-[5px] tracking-[0.038rem] dark:text-gray-400 sm:px-[10px] sm:pt-[25px] lg:px-[40px] xl:px-[58px]">
        <h2
            class="select-none text-ellipsis text-[16px] font-medium leading-5 tracking-[0.038rem] dark:text-white md:text-[18px] lg:text-[20px]">
            {{ title }}
        </h2>
    </div>
    <div class="scoller-outter featured relative overflow-hidden">
        <swiper
            :allowTouchMove="true"
            :loop="false"
            :rewind="false"
            :watchOverflow="true"
            :breakpoints="swiperOptions.breakpoints"
            :freeMode="true"
            :forceToAxis="true"
            :cssMode="swiperOptions.cssMode"
            :touchAngle="swiperOptions.touchAngle"
            :touchEventsTarget="swiperOptions.touchEventsTarget"
            :touchMoveStopPropagation="swiperOptions.touchMoveStopPropagation"
            :touchStartPreventDefault="swiperOptions.touchStartPreventDefault"
            :touchStartForcePreventDefault="swiperOptions.touchStartForcePreventDefault"
            :preventClicksPropagation="swiperOptions.preventClicksPropagation"
            :preventClicks="swiperOptions.preventClicks"
            :mousewheel="swiperOptions.mousewheel"
            :releaseOnEdges="true"
            :navigation="swiperOptions.navigation"
            class="text-white">
            <swiper-slide v-for="item in items" :key="item.id" :wrap-around="true">
                <Link
                    :href="
                        item.lastTile
                            ? route('explore.creators')
                            : route('profile.show', item.profile_url)
                    "
                    class="relative overflow-visible align-top text-black dark:text-white">
                    <div id="avatar-image" class="hover:brightness-110">
                        <div
                            class="overflow-hidden"
                            :class="{ 'rounded-full': rounded, '': !rounded }">
                            <picture
                                v-if="!item.lastTile && item.avatar"
                                class="mb-[8px] block overflow-hidden rounded-full">
                                <source :srcset="item.avatar_images.thumb_webp" type="image/webp" />
                                <source :srcset="item.avatar_images.thumb" type="image/jpeg" />
                                <img
                                    :src="item.avatar_images.thumb_webp"
                                    :alt="item.name"
                                    :title="item.name"
                                    :class="{ 'rounded-full': rounded, '': !rounded }"
                                    class="h-full w-full max-w-[150px] transform overflow-hidden object-cover transition-all duration-500 hover:scale-110 hover:brightness-110"
                                    @error="handleImageError(item)" />
                            </picture>

                            <img
                                v-else-if="item.avatar && item.lastTile"
                                :src="item.avatar"
                                alt="View More Creators"
                                title="View More Creators"
                                :class="{ 'rounded-full': rounded, '': !rounded }"
                                class="mb-[8px] h-full w-full max-w-[150px] transform overflow-hidden object-cover transition-all duration-500 hover:scale-110 hover:brightness-110" />
                            <div
                                v-else
                                :alt="item.name"
                                :title="item.name"
                                class="relative mb-[8px] inline-flex h-full w-full max-w-[150px] transform items-center justify-center overflow-hidden rounded-full object-cover">
                                <div
                                    class="absolute z-10 inline-block h-3/4 w-3/4 justify-center rounded-full bg-neutral-300"></div>
                                <font-awesome-icon
                                    class="z-20 h-full w-full text-neutral-700"
                                    icon="fa-solid fa-circle-user" />
                            </div>
                        </div>

                        <p
                            class="overflow-hidden text-ellipsis text-center text-[13px] leading-[16px] tracking-[0.026rem] text-white hover:brightness-110 dark:text-white sm:text-[14px] sm:leading-[17px]">
                            <span v-if="!item.lastTile">@{{ item.profile_url }}</span>
                            <span v-if="item.lastTile">View More</span>
                        </p>
                    </div>
                </Link>
            </swiper-slide>
        </swiper>
        <div class="carousel-button-prev" tabindex="0" role="button" aria-label="Previous slide">
            <font-awesome-icon
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform md:h-[24px] md:w-[12px] lg:h-[48px] lg:w-[24px]"
                :icon="['fas', 'angle-left']" />
        </div>
        <div class="carousel-button-next" tabindex="0" role="button" aria-label="Next slide">
            <font-awesome-icon
                class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform md:h-[24px] md:w-[12px] lg:h-[48px] lg:w-[24px]"
                :icon="['fas', 'angle-right']" />
        </div>
    </div>
</template>

<style>
    .swiper-button-disabled {
        display: none !important;
    }
    .carousel-button-prev svg,
    .carousel-button-prev path,
    .carousel-button-next svg,
    .carousel-button-next path {
        fill: rgba(255, 255, 255, 0.5) !important;
    }
    .carousel-button-prev2 svg,
    .carousel-button-prev2 path,
    .carousel-button-next2 svg,
    .carousel-button-next2 path {
        fill: rgba(255, 255, 255, 0.5) !important;
    }
    .carousel-button-prev,
    .carousel-button-next {
        position: absolute;
        top: 0;
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        user-select: none;
        justify-content: center;
    }
    .trending-categories .swiper-wrapper {
        max-height: 175px;
    }
    body .swiper {
        margin: 0 58px;
        overflow: hidden;
    }

    .carousel__viewport {
        overflow-y: visible;
    }

    .carousel-button-next {
        color: #fff;
        border-radius: 0px 6px 6px 0px;
        background: #3b3b3b;
        width: 41px;
        height: 100%;
        flex-shrink: 0;
        top: 0px;
        right: 9px;
    }
    .carousel-button-prev {
        color: #fff;
        border-radius: 6px 0px 0px 6px;
        background: #3b3b3b;
        width: 41px;
        height: 100%;
        flex-shrink: 0;
        top: 0px;
        left: 7px;
    }
    .dark .carousel__prev {
        color: #fff;
        border-radius: 6px 0px 0px 6px;
        background: rgba(255, 255, 255, 0.2);
        width: 41px;
        height: 175px;
        flex-shrink: 0;
        top: 87px;
    }
    .carousel__slide {
        justify-content: left;
    }
    .carousel__slide--sliding {
        transition: 0.5s;
    }
    .scoller-outter:hover .carousel-button-prev,
    .scoller-outter:hover .carousel-button-next {
        background-color: #3b3b3b;
        display: block;
    }

    .carousel-button-prev,
    .carousel-button-next {
        display: none;
        color: #ccc;
    }
    .carousel-button-prev:after,
    .swiper-rtl .carousel-button-next:after,
    .carousel-button-next:after,
    .swiper-rtl .carousel-button-prev:after {
        display: none;
    }
    .scoller-outter:hover .carousel-button-prev:after,
    .scoller-outter:hover .swiper-rtl .carousel-button-next:after,
    .scoller-outter:hover .carousel-button-next:after,
    .scoller-outter:hover .swiper-rtl .carousel-button-prev:after {
        display: block;
    }
    @media screen and (max-width: 1279px) {
        body .swiper {
            margin: 0 40px;
        }
        .carousel-button-prev,
        .carousel-button-next {
            width: 29px;
            height: 100%;
        }
        .carousel-button-next {
            right: 6px;
        }
        .carousel-button-prev {
            left: 5px;
        }
    }
    @media screen and (max-width: 1024px) {
        body .swiper {
            margin: 0 0px;
        }
        .swiper-slide:first-of-type {
            margin-left: 10px;
        }
        .carousel-button-prev,
        .carousel-button-next {
            display: none !important;
        }
        .carousel-button-prev2,
        .carousel-button-next2 {
            display: none !important;
        }
    }

    @media screen and (max-width: 640px) {
        body .swiper {
            border-radius: 0;
            margin: 0;
        }

        .carousel-button-prev,
        .carousel-button-next {
            display: none !important;
        }

        .scoller-outter {
            padding: 0;
        }
        .carousel-container .swiper {
            padding-left: 10px;
        }
    }
</style>
