<script setup>
    import { Head, router } from '@inertiajs/vue3'
    import 'vue3-carousel/dist/carousel.css'
    import FollowFeaturedCreatorCarousel from '@/Components/main-components/FollowFeaturedCreatorCarousel.vue'
    import FeaturedCreatorCarousel from '@/Components/main-components/FeaturedCreatorCarousel.vue'
    import HomeSidebar from '@/Components/navigation/HomeSidebar.vue'
    import Announcement from '@/Components/ui-components/Announcement.vue'

    import { ref, onMounted, computed, onBeforeUnmount } from 'vue'
    import { usePage } from '@inertiajs/vue3'
    import CookieConsent from '@/Components/CookieConsent.vue'
    import FeaturedCategories from '@/Components/main-components/FeaturedCategories.vue'
    import 'vue3-carousel/dist/carousel.css'
    import HomeVideos from '@/Components/main-components/HomeVideos.vue'
    import { useVideosStore } from '@/store/trendingVideos.js'
    import posthog from 'posthog-js'

    const { props } = usePage()

    const p = defineProps({
        canLogin: { type: Boolean },
        canRegister: { type: Boolean },
        perPage: { type: Number, default: 0 },
        defaultActiveTab: { type: String, default: 'trending' },
        hasBeenIdentified: { type: Boolean },
    })

    const isLoggedIn = ref(props.auth && props.auth.user && props.auth.user.name !== null)

    const featuredCreators = props.featuredCreators
    const featuredCategories = props.featuredCategories
    const mostRecentVideos = props.mostRecentVideos

    const notification = props.notification ? props.notification : {}
    const alertIsEnabled = props.enabled_notification
    const trendingVideosStore = useVideosStore()
    const featuredCreatorsTitle = 'Featured Creators'
    const followFeaturedCreatorsTitle = 'Follow and Message Your Favorite Stars'
    const featuredCategoryTitle = 'Featured Categories'
    const trendingTitle = 'Trending Videos'
    const MostRecentTitle = 'Most Recent Videos'

    const activeTab = ref(p.defaultActiveTab) // default to 'trending'

    const changeTab = tabName => {
        // console.log(`Changing tab to: ${tabName}`)

        activeTab.value = tabName

        sessionStorage.setItem('activeTab', tabName)
        router.visit(
            route(tabName == 'mostRecent' ? 'home.mostRecent' : 'home.trending', {
                load: 1,
            }),
            {
                replace: true,
                preserveScroll: true,
                preserveState: true,
            },
        )
    }

    onMounted(() => {
        document.body.classList.add('home-page')
        const savedTab = sessionStorage.getItem('activeTab')
        if (savedTab) {
            activeTab.value = savedTab
        }

        trendingVideosStore.restoreState()
        if (trendingVideosStore.trendingVideos.length === 0) {
            trendingVideosStore.setTrendingVideos(props.trendingVideos)
        }
    })
    const trendingVideos = computed(() => trendingVideosStore.trendingVideos)

    const goFullscreen = () => {
        const docEl = document.documentElement

        if (docEl.requestFullscreen) {
            docEl.requestFullscreen()
        } else if (docEl.mozRequestFullScreen) {
            /* Firefox */
            docEl.mozRequestFullScreen()
        } else if (docEl.webkitRequestFullscreen) {
            /* Chrome, Safari & Opera */
            docEl.webkitRequestFullscreen()
        } else if (docEl.msRequestFullscreen) {
            /* IE/Edge */
            docEl.msRequestFullscreen()
        }
    }
    const isChromeMobile = ref(false)

    // Check if the user agent indicates mobile Chrome.
    const chromeMobilePattern = /Chrome\/[.0-9]* Mobile/
    isChromeMobile.value = chromeMobilePattern.test(navigator.userAgent)

    const metaInfo = {
        title: 'Get Extra Naughty with Free Vertical Video Porn from Top Creators',
        description:
            'Extra Naughty is the best fan site for adult creators to share and sell mobile porn videos.',
        image: import.meta.env.VITE_CDN_URL + '/images/exn_OG_image.jpg',
        currentUrl: window.location.href,
    }
    let scrollY
    const saveScrollPosition = () => {
        scrollY = window.scrollY
        document.cookie = `scrollY=${scrollY}; path=/`
    }
    onBeforeUnmount(() => {
        document.body.classList.remove('home-page')
        window.removeEventListener('scroll', saveScrollPosition)
        // Remove the popstate event listener
        window.removeEventListener('popstate', saveScrollPosition)
        saveScrollPosition()
    })
    const storedScrollY = document.cookie.replace(
        /(?:(?:^|.*;\s*)scrollY\s*=\s*([^;]*).*$)|^.*$/,
        '$1',
    )

    onMounted(() => {
        document.body.classList.add('home-page')
        const savedTab = sessionStorage.getItem('activeTab')
        if (savedTab) {
            activeTab.value = savedTab
        }
        window.addEventListener('scroll', saveScrollPosition)
        // Store the scroll position when the user navigates away

        // Listen for the popstate event (user navigates back)
        window.addEventListener('popstate', saveScrollPosition)

        // Check if there's a stored scroll position and scroll to it

        if (storedScrollY) {
            window.scrollTo(0, parseInt(storedScrollY))
        }

        if (window.history.state) {
            console.log('history', window.history.state.url)
        } else {
            console.log('history state is null')
        }
    })
</script>

<template>
    <div>
        <Head>
            <title>{{ metaInfo.title }}</title>
            <meta name="description" :content="metaInfo.description" />
        </Head>

        <CookieConsent />
        <div
            scroll-region
            id="homepage"
            class="relative mx-auto flex w-full max-w-[1536px] pb-[20px]">
            <div id="main-content" class="relative flex-1 overflow-hidden xl:px-0">
                <!--
                    TODO: use notification.background_color to change the behavior of both cases
                    1. notification.is_html = false
                    2. notification.is_html = true
                -->
                <div class="hidden" v-if="notification.is_html">
                    <div v-html="notification.message" />
                </div>

                <Announcement
                    v-if="notification?.message && alertIsEnabled"
                    :background_color="notification.background_color"
                    :alert-content="notification?.message ?? ''"
                    :alert-enabled="alertIsEnabled" />

                <!--                <FollowFeaturedCreatorCarousel-->
                <!--                    v-if="isLoggedIn"-->
                <!--                    :items="featuredCreators"-->
                <!--                    :title="followFeaturedCreatorsTitle" />-->
                <!--                <FeaturedCreatorCarousel-->
                <!--                    v-else-->
                <!--                    :items="featuredCreators"-->
                <!--                    :title="featuredCreatorsTitle" />-->
                <FeaturedCreatorCarousel :items="featuredCreators" :title="featuredCreatorsTitle" />

                <FeaturedCategories :items="featuredCategories" :title="featuredCategoryTitle" />

                <div
                    class="homepage-bottom relative z-[1] mt-[45px] sm:px-[0px] md:px-[0px] lg:px-[40px] xl:px-[58px]">
                    <div
                        class="relative z-[1] mx-[10px] mb-[15px] flex flex-wrap gap-[28px] border-b border-bordergray sm:mx-[10px] sm:mb-[19px] sm:gap-[27px] md:mx-0 md:mx-[10px]">
                        <div
                            class="z-10 w-auto flex-none cursor-pointer border-b-[1px] bg-transparent pb-[15px] text-[16px] font-medium tracking-[0.038rem] focus:outline-none dark:hover:text-white sm:pb-[19px] md:flex-none md:text-[18px] lg:text-[20px]"
                            :class="{
                                'border-mintgreen text-black dark:text-white':
                                    activeTab === 'trending',
                                'border-transparent text-lightgray dark:text-lightgray':
                                    activeTab !== 'trending',
                            }"
                            @click="changeTab('trending')">
                            Trending Videos
                        </div>
                        <div
                            class="z-10 w-auto flex-none cursor-pointer border-b-[1px] bg-transparent pb-[15px] text-[16px] font-medium tracking-[0.038rem] focus:outline-none dark:hover:text-white sm:pb-[19px] md:flex-none md:text-[18px] lg:text-[20px]"
                            :class="{
                                'border-mintgreen text-black dark:text-white':
                                    activeTab === 'mostRecent',
                                'border-transparent text-lightgray dark:text-lightgray':
                                    activeTab !== 'mostRecent',
                            }"
                            @click="changeTab('mostRecent')">
                            Most Recent
                        </div>
                    </div>
                    <HomeVideos
                        id="trendingVideos"
                        v-if="activeTab === 'trending'"
                        :activeTab="activeTab"
                        :loadMore="route('home.loadMore.trendingVideos')"
                        :perPage="perPage"
                        :trendingItems="trendingVideos"
                        filter="trending"
                        :title="trendingTitle" />
                    <HomeVideos
                        id="mostRecentVideos"
                        v-if="activeTab === 'mostRecent'"
                        :loadMore="route('home.loadMore.mostRecent')"
                        :perPage="perPage"
                        :trendingItems="mostRecentVideos"
                        :activeTab="activeTab"
                        filter="most-recent"
                        :title="MostRecentTitle" />
                </div>
            </div>

            <HomeSidebar />
        </div>
    </div>
</template>
<style>
    /*.home-page #nprogress .bar {
        display: none;
    }*/
</style>

<style scoped>
    .bg-dots-darker {
        background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(0,0,0,0.07)'/%3E%3C/svg%3E");
    }

    body {
        margin-bottom: 0 !important;
    }

    @media (prefers-color-scheme: dark) {
        .dark\:bg-dots-lighter {
            background-image: url("data:image/svg+xml,%3Csvg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.22676 0C1.91374 0 2.45351 0.539773 2.45351 1.22676C2.45351 1.91374 1.91374 2.45351 1.22676 2.45351C0.539773 2.45351 0 1.91374 0 1.22676C0 0.539773 0.539773 0 1.22676 0Z' fill='rgba(255,255,255,0.07)'/%3E%3C/svg%3E");
        }
    }
</style>
