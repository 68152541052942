<script setup>
    import { Navigation, Pagination, Scrollbar, A11y, FreeMode, Mousewheel } from 'swiper/modules'
    import { Swiper, SwiperSlide } from 'swiper/vue'
    import 'swiper/css'
    import 'swiper/css/navigation'
    import 'swiper/css/pagination'
    import 'swiper/css/scrollbar'
    import { Link } from '@inertiajs/vue3'
    import { onMounted, onBeforeUnmount } from 'vue'

    const props = defineProps(['items', 'title'])

    // Destructure the props
    const { items } = props

    const onTouchMove = event => {
        event.preventDefault()
    }
    const modules = [Navigation, Scrollbar, A11y, FreeMode, Mousewheel]

    const swiperOptions = {
        // Default parameters
        mousewheel: {
            forceToAxis: true,
            sensitivity: 1,
            releaseOnEdges: true,
        },

        navigation: {
            enabled: true,
            prevEl: '.carousel-button-prev2',
            nextEl: '.carousel-button-next2',
        },
        cssMode: true,
        touchStartPreventDefault: true,
        touchMoveStopPropagation: false,
        touchStartForcePreventDefault: true,
        preventClicksPropagation: true,
        preventClicks: true,
        touchAngle: 45,
        touchEventsTarget: 'wrapper',
        longSwipesMs: 300,

        // Responsive breakpoints
        breakpoints: {
            // when window width is >= 320px
            320: {
                slidesPerView: 2.5,
                spaceBetween: 6,
            },
            // when window width is >= 480px
            480: {
                slidesPerView: 3.5,
                spaceBetween: 10,
            },
            // when window width is >= 640px
            640: {
                slidesPerView: 3.5,
                slidesPerGroup: 3,
                spaceBetween: 13,
            },
            1000: {
                slidesPerView: 3.5,
                slidesPerGroup: 3,
                spaceBetween: 13,
            },
            1024: {
                slidesPerView: 4,
                slidesPerGroup: 4,
                spaceBetween: 13,
            },
        },
    }
    if (!items.find(item => item.id === 50)) {
        items.push({
            id: 50,
            profile_url: 'View More',
            slug: 'all',

            start_card_image: import.meta.env.VITE_CDN_URL + '/images/view-more.jpg',
            name: 'View More',
            viewmore: true,
            lastTile: true,
        })
    }

    const handleImageError = item => {
        // This function will be called when the image fails to load
        // You can set a backup image source here
        item.start_card_images.image_452x350_webp = '/images/broken-image.jpg'
    }
</script>

<template>
    <div
        id="recommended"
        class="relative mx-auto pb-5 pl-[10px] pt-[33px] dark:text-gray-400 sm:px-[10px] sm:pb-5 sm:pt-[50px] lg:px-[40px] xl:pl-[56px] xl:pr-[60px]">
        <h2
            class="select-none text-ellipsis text-[16px] font-medium leading-5 tracking-[0.038rem] dark:text-white md:text-[18px] lg:text-[20px]">
            {{ title }}
        </h2>
    </div>
    <div class="pb-[33px] sm:pb-[0px]">
        <div class="carousel-container trending-categories overflow-hidden">
            <div class="scoller-outter relative">
                <swiper
                    :allowTouchMove="true"
                    :loop="false"
                    :rewind="false"
                    :watchOverflow="true"
                    :breakpoints="swiperOptions.breakpoints"
                    :freeMode="true"
                    :forceToAxis="true"
                    :cssMode="swiperOptions.cssMode"
                    :touchAngle="swiperOptions.touchAngle"
                    :touchEventsTarget="swiperOptions.touchEventsTarget"
                    :touchMoveStopPropagation="swiperOptions.touchMoveStopPropagation"
                    :touchStartPreventDefault="swiperOptions.touchStartPreventDefault"
                    :touchStartForcePreventDefault="swiperOptions.touchStartForcePreventDefault"
                    :preventClicksPropagation="swiperOptions.preventClicksPropagation"
                    :preventClicks="swiperOptions.preventClicks"
                    :mousewheel="swiperOptions.mousewheel"
                    :releaseOnEdges="true"
                    :navigation="swiperOptions.navigation"
                    class="text-white">
                    <swiper-slide v-for="item in items" :key="item.id" :wrap-around="true">
                        <Link
                            preserve-scroll
                            :href="
                                item.lastTile
                                    ? route('explore.tags')
                                    : route('tags.show', [
                                          item.slug,
                                          {
                                              load: 1,
                                          },
                                      ])
                            "
                            class="relative overflow-visible align-top text-black dark:text-white">
                            <div class="overflow-hidden rounded-md hover:brightness-110">
                                <div class="relative">
                                    <div class="hover-overlay relative overflow-hidden">
                                        <div v-if="!item.viewmore" class="gradient-overlay"></div>
                                        <picture v-if="item.start_card_images">
                                            <source
                                                :srcset="item.start_card_images.image_452x350_webp"
                                                type="image/webp" />
                                            <source
                                                :srcset="item.start_card_images.image_452x350"
                                                type="image/jpeg" />
                                            <img
                                                :src="item.start_card_images.image_452x350_webp"
                                                :alt="item.name"
                                                :title="item.name"
                                                class="aspect-[226/175] h-full max-h-[175px] w-full transform overflow-hidden border-white object-cover transition-all duration-500 hover:scale-110 hover:brightness-110"
                                                @error="handleImageError(item)" />
                                        </picture>
                                        <img
                                            v-else
                                            src="./../../../../public/images/view-more.jpg"
                                            alt="View More Categories"
                                            title="View More Categories"
                                            class="h-full max-h-[175px] w-full transform overflow-hidden border-white object-cover transition-all duration-500 hover:scale-110 hover:brightness-110" />
                                    </div>

                                    <p
                                        class="absolute bottom-2.5 z-20 w-full text-center text-[14px] font-semibold capitalize leading-[17px] tracking-[0.026rem] text-white dark:text-white">
                                        {{ item.name }}
                                    </p>
                                </div>
                            </div>
                        </Link>
                    </swiper-slide>
                </swiper>
                <div
                    class="carousel-button-prev2"
                    tabindex="0"
                    role="button"
                    aria-label="Previous slide">
                    <font-awesome-icon
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform md:h-[24px] md:w-[12px] lg:h-[48px] lg:w-[24px]"
                        :icon="['fas', 'angle-left']" />
                </div>
                <div
                    class="carousel-button-next2"
                    tabindex="0"
                    role="button"
                    aria-label="Next slide">
                    <font-awesome-icon
                        class="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform md:h-[24px] md:w-[12px] lg:h-[48px] lg:w-[24px]"
                        :icon="['fas', 'angle-right']" />
                </div>
            </div>
        </div>
    </div>
</template>

<style>
    .hover-overlay:hover .gradient-overlay {
        display: none;
    }
    .gradient-overlay {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.21) 65.71%, rgba(0, 0, 0, 0.7) 100%);
        height: 100%;
        width: 100%;
        position: absolute;
        z-index: 10;
        border-radius: 6px;
    }
    .carousel-button-prev2,
    .carousel-button-next2 {
        position: absolute;
        top: 0;
        z-index: 10;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
    }

    .trending-categories .swiper-wrapper {
        max-height: 175px;
    }

    .carousel__viewport {
        overflow-y: visible;
    }
    .carousel-button-next2 {
        color: #fff;
        border-radius: 0px 6px 6px 0px;
        background: #3b3b3b;
        width: 41px;
        height: 100%;
        flex-shrink: 0;
        top: 0px;
        right: 9px;
    }
    .carousel-button-prev2 {
        color: #fff;
        border-radius: 6px 0px 0px 6px;
        background: #3b3b3b;
        width: 41px;
        height: 100%;
        flex-shrink: 0;
        top: 0px;
        left: 7px;
    }
    .dark .carousel__prev {
        color: #fff;
        border-radius: 6px 0px 0px 6px;
        background: rgba(255, 255, 255, 0.2);
        width: 41px;
        height: 100%;
        flex-shrink: 0;
        top: 87px;
    }
    .carousel__slide {
        justify-content: left;
    }
    .carousel__slide--sliding {
        transition: 0.5s;
    }
    .scoller-outter:hover .carousel-button-prev2,
    .scoller-outter:hover .carousel-button-next2 {
        background-color: #3b3b3b;
        display: block;
    }

    .carousel-button-prev2,
    .carousel-button-next2 {
        display: none;
        color: #ccc;
    }
    .carousel-button-prev2:after,
    .swiper-rtl .carousel-button-next2:after,
    .carousel-button-next2:after,
    .swiper-rtl .carousel-button-prev2:after {
        display: none;
    }
    .scoller-outter:hover .carousel-button-prev2:after,
    .scoller-outter:hover .swiper-rtl .carousel-button-next2:after,
    .scoller-outter:hover .carousel-button-next2:after,
    .scoller-outter:hover .swiper-rtl .carousel-button-prev2:after {
        display: block;
    }
    .swiper-wrapper:focus .carousel-button-prev2,
    .swiper-wrapper:hover .carousel-button-next2 {
        display: block;
    }
    @media screen and (max-width: 1279px) {
        .carousel-button-prev2,
        .carousel-button-next2 {
            width: 29px;
        }
        .carousel-button-next2 {
            right: 6px;
        }
        .carousel-button-prev2 {
            left: 5px;
        }
    }
    @media screen and (max-width: 1023px) {
        .carousel-button-prev2,
        .carousel-button-next2 {
            width: 20px;
            height: 100%;
        }
    }
    @media screen and (max-width: 640px) {
        .carousel-container .swiper {
            border-radius: 0;
        }

        .carousel-button-prev2,
        .carousel-button-next2 {
            display: none !important;
        }
        .scoller-outter {
            padding: 0;
        }
        .carousel-container .swiper {
            padding-left: 0px;
            padding-right: 0px;
        }
        .swiper-slide:first-of-type {
            margin-left: 10px;
        }
    }
</style>
