<template>
    <div id="trending" class="mx-auto py-6 pt-0 dark:bg-black dark:text-gray-400 sm:pr-0">
        <div :class="cssClasses" id="infinite-list" ref="scrollComponent">
            <div
                class="relative"
                :class="`item-${index}`"
                v-for="(item, index) in itemsWithVideo"
                :key="item.id">
                <Link
                    preserve-scroll
                    href="#"
                    @click.prevent="() => navigate(item, index)"
                    class="relative flex aspect-[576/1024] h-full w-full cursor-pointer overflow-hidden rounded-none text-black shadow-lg lg:rounded-md"
                    @mouseenter="showVideo(item)"
                    @mouseleave="hideVideo(item)"
                    @touchstart="showVideo(item)"
                    @touchenter="showVideo(item)"
                    @touchmove="showVideo(item)">
                    <picture>
                        <source
                            :srcset="item.start_card_images.image_600x1066_webp"
                            type="image/webp" />
                        <source
                            :srcset="
                                item.start_card_images.image_600x1066
                                    ? item.start_card_images.image_600x1066
                                    : '/images/brokenImage.png'
                            "
                            type="image/jpeg" />

                        <img
                            :title="item.title"
                            :alt="item.title"
                            alt="Image"
                            width="297"
                            height="526"
                            :class="{
                                'is_not_vertical h-full w-full object-contain text-white':
                                    !item.is_vertical,
                                'is_vertical h-full w-full object-cover text-white':
                                    item.is_vertical,
                                locked: item.is_locked,
                                unlocked: !item.is_locked,
                            }"
                            v-show="!item.showVideo"
                            :src="item.start_card_images.image_600x1066_webp" />
                    </picture>

                    <div
                        v-if="item.buffering && !item.is_locked && item.showVideo"
                        class="loading spinner z-[100]">
                        <!-- Use a custom spinner or any loading component here -->
                        <span class="text-white"></span>
                    </div>
                    <video
                        :ref="videoRef => (item.videoElement = videoRef)"
                        :poster="item.start_card_images.image_600x1066_webp"
                        class="h-auto w-full"
                        :title="item.title"
                        :alt="item.title"
                        autoplay="autoplay"
                        muted="muted"
                        loop="loop"
                        webkit-playsinline
                        playsinline
                        preload="none"
                        @waiting="() => setBufferingState(item, true)"
                        @playing="() => setBufferingState(item, false)"
                        @loadstart="() => setBufferingState(item, true)"
                        disableremoteplayback
                        v-if="item.showVideo">
                        <!--<source :src="item.is_locked ? '' : item.video_web" type="video/webm" />-->
                        <source :src="item.is_locked ? '' : item.video_mp4" type="video/mp4" />
                    </video>

                    <div class="absolute bottom-[13px] left-[11px] flex hidden">
                        <font-awesome-icon class="text-white" :icon="['fas', 'play']" />
                        <div class="pl-2 text-[15px] leading-[17px] text-white">
                            {{ item.views }}
                        </div>
                    </div>
                    <div
                        v-if="item.payment_type == 2"
                        class="absolute bottom-[10px] right-[10px] hidden rounded-md px-[3px] py-[0px] text-[14px] text-white">
                        {{ item.duration ?? '' }}
                    </div>
                    <SubscribePpvOverlay v-if="item.is_locked" :item="item" />
                    <VideoDuration v-if="item.is_locked" :item="item" />
                </Link>
            </div>
        </div>
    </div>
</template>

<script setup>
    import { ref, computed, watchEffect, onMounted, onUnmounted } from 'vue'
    import { Link, router } from '@inertiajs/vue3'

    import SubscribePpvOverlay from '@/Components/main-components/SubscribePpvOverlay.vue'

    import VideoDuration from '@/Components/VideoDuration.vue'
    import { useMutedStore } from '@/store/isMuted'
    import { useVideosStore } from '@/store/trendingVideos.js'

    const trendingVideosStore = useVideosStore()
    const IsmutedStore = useMutedStore()
    IsmutedStore.isMuted = false

    const props = defineProps([
        'trendingItems',
        'title',
        'styling',
        'loadMore',
        'perPage',
        'filter',
        'activeTab',
    ])
    const isIOS = computed(() => {
        return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    })
    const navigate = (item, index) => {
        let params = {
            type: 'global',
            firstFilter: props.filter,
            secondFilter: 'free',
            post: item.slug,
        }
        if (props.filter == 'trending') {
            params.activeTab = props.activeTab
            params.currentIndex = index
            params.random = item.randomValue
            if (item.is_random) {
                params.is_random = item.is_random
            }
        }
        router.visit(route('feeds.index', params), {
            preserveScroll: true,
            preserveState: true,
        })
    }

    const rows = computed(() => {
        // Check if the active tab is 'trending'
        if (props.activeTab === 'trending') {
            // Use data from the store
            return trendingVideosStore.trendingVideos
        } else {
            // Use passed-in prop data
            return props.trendingItems
        }
    })
    const itemsWithVideo = ref([])
    const scrollComponent = ref(null)
    itemsWithVideo.value = rows.value
        ? rows.value.map(item => ({ ...item, showVideo: false, videoElement: null }))
        : []
    // Ensures that itemsWithVideo is updated with the new trendingItems and that showVideo is set to false for all of them
    watchEffect(() => {
        itemsWithVideo.value = rows.value
            ? rows.value.map(item => ({ ...item, showVideo: false }))
            : []
    })
    const styling = ref(
        props.styling ??
            'mb-6 grid grid-cols-2 gap-[5px] lg:gap-[20px] sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3',
    )

    const cssClasses = computed(() => styling.value)
    const currentPlayingVideo = ref(null)
    const playingItem = ref(null)
    // Define the showVideo and hideVideo functions
    const showVideo = item => {
        if (playingItem.value !== null && playingItem.value !== item) {
            hideVideo(playingItem.value)
        }

        item.showVideo = true
        playingItem.value = item
    }

    const hideVideo = item => {
        if (playingItem.value === item) {
            playingItem.value = null
        }
        item.showVideo = false
    }

    let page = 1
    let loading = false
    const updatePathForPhantom = pageNo => {
        router.visit(
            route(props.filter == 'most-recent' ? 'home.mostRecent' : 'home.trending', {
                load: pageNo,
            }),
            {
                replace: true,
                preserveScroll: true,
                preserveState: true,
            },
        )
    }
    const loadMorePosts = () => {
        // Prevent loading if already loading or if the active tab is not 'trending'
        if (loading) {
            return
        }
        loading = true

        let params = { page: ++page }
        if (props.filter === 'trending') {
            params.random = props.trendingItems[0].randomValue
        }

        axios
            .post(props.loadMore, params)
            .then(response => {
                if (response.data && Array.isArray(response.data)) {
                    // Add videos to the store only if on the 'trending' tab

                    if (props.activeTab === 'trending') {
                        // console.log('Adding videos to the store:')
                        trendingVideosStore.addVideos(response.data)
                    } else {
                        const existingIds = new Set(rows.value.map(item => item.id))
                        const newItems = response.data.filter(item => !existingIds.has(item.id))
                        rows.value.push(...newItems)
                        //  console.log('Adding videos to the rows:')
                    }

                    if (response.data.length < props.perPage) {
                        window.removeEventListener('scroll', handleScroll, { passive: true })
                    }
                    updatePathForPhantom(page)
                } else {
                    console.error('Received unexpected data:', response.data)
                }
            })
            .catch(error => {
                // Error handling for the axios request
                console.error('Error fetching more posts:', error)
            })
            .finally(() => (loading = false))
    }

    onMounted(() => {
        if (rows.value.length && props.perPage) {
            let items = parseInt(rows.value.length, 10)
            let perPage = parseInt(props.perPage, 10)
            if (props.filter == 'trending') {
                items -= 9
            }
            if (items > perPage) {
                page = Math.ceil(items / perPage)
            }
        }
        window.addEventListener('scroll', handleScroll, { passive: true })
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', handleScroll, { passive: true })
    })

    const handleScroll = e => {
        let element = scrollComponent.value
        if (element.getBoundingClientRect().bottom < window.innerHeight) {
            loadMorePosts()
        }
    }

    itemsWithVideo.value = rows.value
        ? rows.value.map(item => ({ ...item, showVideo: false, buffering: false }))
        : []
    const setBufferingState = (item, state) => {
        item.buffering = state
    }
</script>

<style>
    .spinner {
        border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
        border-top: 4px solid #3498db; /* Blue */
        border-radius: 50%;
        width: 50px;
        height: 50px;
        animation-name: fadeIn, spin;
        animation-duration: 1s, 2s; /* 1s for fading, 2s for spinning */
        animation-timing-function: ease-in; /* This applies to both animations. Adjust if needed. */
        animation-iteration-count: 1, infinite; /* 'fadeIn' plays once; 'spin' continues indefinitely */
        animation-fill-mode: forwards;
        position: absolute;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
    }
    @keyframes fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes spin {
        0% {
            transform: translate(-50%, -50%) rotate(0deg);
        }
        100% {
            transform: translate(-50%, -50%) rotate(360deg);
        }
    }
    /* Styles for your loading spinner and video container */
    .video-container {
        position: relative;
        /* ... other styles as needed ... */
    }

    .loading {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        /* Additional styling for the spinner or use a CSS/SVG animation */
    }
</style>
